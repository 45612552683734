<template>
    <v-card
        elevation="0"
        rounded="lg"
        class="product-action px-3 pt-1 pb-2"
        :class="{ 'reduced-width': $vuetify.breakpoint.mdAndUp }"
    >
        <v-card-title
            class="d-block action-title text-body-1 font-weight-bold pb-3"
        >
            <slot name="title"></slot>
        </v-card-title>
        <v-card-text class="flex-grow-1 text-body-2 pb-1">
            <slot name="description"></slot>
        </v-card-text>
        <v-card-actions class="ml-2 pb-4">
            <a
                :href="link"
                target="_blank"
                class="font-weight-bold text-body-2"
            >
                <slot name="button"></slot>
                <v-icon color="primary" x-small right>arrow-right</v-icon>
            </a>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ProductActionProps = Vue.extend({
    name: 'ProductAction',
    props: {
        link: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class ProductAction extends ProductActionProps {}
</script>

<style lang="scss" scoped>
.product-action::v-deep {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.reduced-width {
        max-width: 36ch;
    }

    .action-title {
        word-break: keep-all;
    }
}
</style>
